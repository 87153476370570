/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import {
    object, func,
} from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { compose } from 'recompose';
import { trackEvent as track } from '../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import { getCurrentPageView } from '../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Selectors';
import { getOperatingSystem } from '../../../../../state/ducks/App/ducks/Config/Config-Helpers';
import { getFeatureFlags, getCurrentBrandId } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { determineChatlink } from '../../../MultiBrandChatController/Global_chat_helpers';

const styles = (theme) => ({
    phoneBlock: {
        display: 'block',
        fontFamily: theme.typography.fontFamily,
    },
    heading: {
        textAlign: 'center',
        fontSize: '18px',
        color: 'black',
    },
    subHeading: {
        textAlign: 'center',
        fontSize: '14px',
        padding: '10px 0px',
        color: 'black',
    },
    chatIcon: {
        display: 'flex',
        flexDirection: 'column',
        alignItem: 'center',
        padding: '7.73832px 19.3458px',
        width: '132.18px',
        height: '35.48px',
        background: theme.palette.bgChatMessage,
        border: `0.96729px solid ${theme.palette.bgChatMessage} `,
        borderRadius: '2.90187px',
        flex: 'none',
        order: 0,
        alignSelf: 'stretch',
        flexGrow: 0,
        position: 'relative',
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        gap: '10px',
    },
    chatLayout: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px',
        gap: '13.54px',
        width: '93.49px',
        height: '20px',
        flex: 'none',
        order: 0,
        flexGrow: 0,
    },
    liveChatText: {
        width: 'fit-content',
        height: '20px',
        // fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '12px',
        lineHeight: '19px',
        display: 'flex',
        alignItem: 'center',
        textAlign: 'center',
        letterSpacing: '0.025em',
        color: '#FFFFFF',
        flex: 'none',
        order: 1,
        flexGrow: 0,
    },
});

const CustomerServiceBlock = ({
    classes, trackEvent,
}) => {
    const operatingSystem = getOperatingSystem().toLowerCase();
    const featureFlags = useSelector((state) => getFeatureFlags(state));
    const customerData = useSelector((state) => state?.member);
    const currentBrand = useSelector((state) => getCurrentBrandId(state));
    const isPDP = (featureFlags['is-pdp-mobile-chat-enabled'] && typeof document !== 'undefined' && document?.querySelector("[data-pagetype='product']"));
    const isMobileChatEnabled = ((featureFlags['is-gbm-in-pwa-enabled'] && operatingSystem === 'android') || (featureFlags['is-abc-in-pwa-enabled'] && operatingSystem === 'ios'));

    return (
        <>
            {
                featureFlags['is-global-cs-mobile-footer-enabled'] && !isPDP
                    ? (
                        <div id="footerChatRegion" className={classes.phoneBlock}>
                            <div className={classes.heading}>
                                Have Questions?
                            </div>
                            <div className={classes.subHeading}>
                                {/* // eslint-disable-next-line react/jsx-curly-brace-presence */}
                                {"We'll help you find answers"}
                            </div>
                            <div className={classes.subHeading}>
                                <div className={classes.container} style={!isMobileChatEnabled ? { justifyContent: 'center', paddingLeft: '0px' } : { hidden: false }}>
                                    {isMobileChatEnabled
                                        && (
                                            <a
                                                href={determineChatlink(operatingSystem, currentBrand, featureFlags, customerData)}
                                                onClick={() => trackEvent({
                                                    eventCategory: 'Mobile',
                                                    eventAction: 'Chat opened',
                                                    eventLabel: 'Chat',
                                                })}
                                                aria-hidden="true"
                                                tabIndex="-1"
                                                style={{ textDecoration: 'none' }}
                                            >
                                                <span className={classes.chatIcon}>
                                                    <span className={classes.chatLayout}>
                                                        <svg width="20" height="18" viewBox="0 0 20 18" fill="white" xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M0.663788 4.78358C0.663788 2.65237 2.39148 0.924683 4.52269 0.924683H15.7486C17.8798 0.924683 19.6075 2.65237 19.6075 4.78358V10.0457C19.6075 12.1769 17.8798 13.9046 15.7486 13.9046H8.9078L5.599 17.2134C5.26714 17.5453 4.69975 17.3096 4.7007 16.8403L4.70668 13.9046H4.52269C2.39148 13.9046 0.663788 12.1769 0.663788 10.0457V4.78358ZM6.01362 8.81788C6.74017 8.81788 7.32916 8.2289 7.32916 7.50235C7.32916 6.7758 6.74017 6.18682 6.01362 6.18682C5.28707 6.18682 4.69809 6.7758 4.69809 7.50235C4.69809 8.2289 5.28707 8.81788 6.01362 8.81788ZM11.5389 7.50235C11.5389 8.2289 10.9499 8.81788 10.2233 8.81788C9.49678 8.81788 8.9078 8.2289 8.9078 7.50235C8.9078 6.7758 9.49678 6.18682 10.2233 6.18682C10.9499 6.18682 11.5389 6.7758 11.5389 7.50235ZM14.433 8.81788C15.1596 8.81788 15.7486 8.2289 15.7486 7.50235C15.7486 6.7758 15.1596 6.18682 14.433 6.18682C13.7065 6.18682 13.1175 6.7758 13.1175 7.50235C13.1175 8.2289 13.7065 8.81788 14.433 8.81788Z" />
                                                        </svg>
                                                        <span className={classes.liveChatText}>Live Chat</span>
                                                    </span>
                                                </span>
                                            </a>
                                        )}
                                </div>
                            </div>
                        </div>
                    )
                    : null
            }
        </>
    );
};

CustomerServiceBlock.propTypes = {
    classes: object.isRequired,
    trackEvent: func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
    trackEvent: bindActionCreators(track, dispatch),
});
const mapStateToProps = (state) => ({
    pageView: getCurrentPageView(state),
});

const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
);

export default enhance(CustomerServiceBlock);
